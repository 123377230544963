// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accucam-js": () => import("../src/pages/accucam.js" /* webpackChunkName: "component---src-pages-accucam-js" */),
  "component---src-pages-accufab-js": () => import("../src/pages/accufab.js" /* webpackChunkName: "component---src-pages-accufab-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imanufacture-js": () => import("../src/pages/imanufacture.js" /* webpackChunkName: "component---src-pages-imanufacture-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mcstatus-js": () => import("../src/pages/mcstatus.js" /* webpackChunkName: "component---src-pages-mcstatus-js" */),
  "component---src-pages-mx-js": () => import("../src/pages/mx.js" /* webpackChunkName: "component---src-pages-mx-js" */),
  "component---src-pages-mxmrp-js": () => import("../src/pages/mxmrp.js" /* webpackChunkName: "component---src-pages-mxmrp-js" */),
  "component---src-pages-mxpallet-js": () => import("../src/pages/mxpallet.js" /* webpackChunkName: "component---src-pages-mxpallet-js" */),
  "component---src-pages-mxreport-js": () => import("../src/pages/mxreport.js" /* webpackChunkName: "component---src-pages-mxreport-js" */),
  "component---src-pages-mxstation-js": () => import("../src/pages/mxstation.js" /* webpackChunkName: "component---src-pages-mxstation-js" */),
  "component---src-pages-mxticket-js": () => import("../src/pages/mxticket.js" /* webpackChunkName: "component---src-pages-mxticket-js" */),
  "component---src-pages-nesting-js": () => import("../src/pages/nesting.js" /* webpackChunkName: "component---src-pages-nesting-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

